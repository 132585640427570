<template>
  <v-card>
    <v-card-title>Summary</v-card-title>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Inserted: {{ uploadStats.inserted.length }}</th>
            <th>Updated: {{ updatedProducts }}</th>
            <th>Missing Images: {{ uploadStats.missingImages.length }}</th>
            <th>Unchanged: {{ uploadStats.unchanged.length }}</th>
            <th>Total: {{ uploadStats.total }}</th>
          </tr>
        </thead>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    uploadStats: Object
  },
  computed: {
    updatedProducts: function () { return Object.keys(_.groupBy(this.uploadStats.updated, 'sku')).length }
  },
  data () {
    return {
    }
  }
}
</script>
